import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  setDoc,
} from 'firebase/firestore';
import {
  CommonContainer,
  CommonButton,
  CommonTable,
  CommonTitle,
  CommonTableHeader,
  CommonTableCell,
  CommonTableRow,
  CommonEllipsisDropdown,
  CommonEllipsisButton,
  CommonEllipsisContent,
  CommonEllipsisItem,
  CommonBottomBar,
} from './CommonStyles';

const LoanDebtList = () => {
  const [expenses, setExpenses] = useState([]);
  const [sortField, setSortField] = useState('amount');
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalExcludingSpecific, setTotalExcludingSpecific] = useState(0);
  const [expandedExpense, setExpandedExpense] = useState(null); // Add this line
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };
  useEffect(() => {
    const totalExcluded = expenses
      .filter(expense => expense.title !== 'Mortgage' && expense.title !== 'Student loans Kendra' && !expense.title.includes('Tacoma') && expense.title !== 'Student loans Jeremiah')
      .reduce((acc, curr) => acc + Number(curr.amount), 0);
    setTotalExcludingSpecific(totalExcluded);
  }, [expenses]);

  useEffect(() => {
    const fetchDataAndSubscribe = async () => {
      // Fetch data without applying orderBy here, sorting will be done in front-end
      const expensesCollection = collection(firestore, 'unexpected-expenses');
      try {
        const snapshot = await getDocs(expensesCollection);
        const expensesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setExpenses(expensesData);
      } catch (error) {
        console.error('Error fetching documents: ', error);
      }
    };

    fetchDataAndSubscribe();
  }, []);

  const sortedExpenses = expenses.map(expense => {
    const totalAmount = expense['sub-loans']
      ? expense.amount + expense['sub-loans'].reduce((sum, subLoan) => sum + subLoan.amount, 0)
      : expense.amount;

    return {
      ...expense,
      totalAmount,
    };
  }).sort((a, b) => {
    if (a.totalAmount < b.totalAmount) return sortOrder === 'asc' ? -1 : 1;
    if (a.totalAmount > b.totalAmount) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const handleSort = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const addExpense = async () => {
    const newExpense = { title: 'New Expense', amount: 0, rate: 0 };
    try {
      const expenseRef = await addDoc(collection(firestore, 'unexpected-expenses'), newExpense);
      setExpenses([...expenses, { id: expenseRef.id, ...newExpense }]);
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const deleteExpense = async (id) => {
    try {
      await deleteDoc(doc(firestore, 'unexpected-expenses', id));
      setExpenses(expenses.filter((expense) => expense.id !== id));
    } catch (error) {
      console.error('Error removing document: ', error);
    }
  };

  const editExpense = async (id, currentExpense) => {
    try {
      const expenseDocRef = doc(firestore, 'unexpected-expenses', id);
      const updatedExpenseData = {
        title: prompt('Enter new title:', currentExpense.title) || currentExpense.title,
        amount: parseFloat(prompt('Enter new amount:', currentExpense.amount) || currentExpense.amount),
        rate: parseFloat(prompt('Enter new interest rate:', currentExpense.rate) || currentExpense.rate),
      };
      await setDoc(expenseDocRef, updatedExpenseData, { merge: true });
      setExpenses((prevExpenses) =>
        prevExpenses.map((expense) => (expense.id === id ? { ...expense, ...updatedExpenseData } : expense))
      );
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <CommonContainer>
      <CommonTitle>Loans & Debts</CommonTitle>
      <CommonButton onClick={addExpense}>Add New Expense</CommonButton>

      <CommonTable>
        <thead>
          <CommonTableRow>
            <CommonTableHeader onClick={() => handleSort('title')}>Title</CommonTableHeader>
            <CommonTableHeader onClick={() => handleSort('amount')}>Amount</CommonTableHeader>
            <CommonTableHeader>Actions</CommonTableHeader>
          </CommonTableRow>
        </thead>
        <tbody>
          {sortedExpenses.map((expense) => (
            <>
              <CommonTableRow key={expense.id} onClick={() => setExpandedExpense(expense.id === expandedExpense ? null : expense.id)}>
                <CommonTableCell>{expense.title}</CommonTableCell>
                <CommonTableCell>${expense.totalAmount} {typeof expense.rate === 'number' && expense.rate !== 0 ? `@ ${(expense.rate * 100).toFixed(2)}%` : ''}</CommonTableCell>
                <CommonTableCell>
                  <CommonEllipsisDropdown>
                    <CommonEllipsisButton onClick={() => toggleDropdown(expense.id)}>...</CommonEllipsisButton>
                    {visibleDropdownId === expense.id && (
                      <CommonEllipsisContent>
                        <CommonEllipsisItem onClick={() => deleteExpense(expense.id)}>Delete</CommonEllipsisItem>
                        <CommonEllipsisItem
                          onClick={() => editExpense(expense.id, expense)}
                        >
                          Edit
                        </CommonEllipsisItem>
                      </CommonEllipsisContent>
                    )}
                  </CommonEllipsisDropdown>
                </CommonTableCell>
              </CommonTableRow>
              {expense.id === expandedExpense && expense['sub-loans'] && expense['sub-loans'].length > 0 && (
                <CommonTableRow>
                  <CommonTableCell colSpan={3}>
                    <CommonTable>
                      <tbody>
                        {expense['sub-loans'].sort((a, b) => a.amount - b.amount).map((subLoan, index) => (
                          <CommonTableRow key={index}>
                            <CommonTableCell>{subLoan.title}</CommonTableCell>
                            <CommonTableCell>${subLoan.amount}</CommonTableCell>
                            <CommonTableCell>{typeof subLoan.rate === 'number' && subLoan.rate !== 0 ? `${(subLoan.rate * 100).toFixed(2)}%` : ''}</CommonTableCell>
                          </CommonTableRow>
                        ))}
                      </tbody>
                    </CommonTable>
                  </CommonTableCell>
                </CommonTableRow>
              )}
            </>
          ))}
        </tbody>
      </CommonTable>
      <CommonBottomBar>
        <div>Total (Excl. Mortgage & Student Loans & Tacoma): ${totalExcludingSpecific.toFixed(2)}</div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default LoanDebtList;
