import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LayoutContainer from './LayoutContainer';
import BudgetSummary from './pages/BudgetSummary';
import Transactions from './pages/Transactions';
import LoanDebt from './pages/LoanDebt'
import ShoppingList from './pages/ShoppingList';
import Notes from './pages/Notes';
import BillsList from './pages/BillsList';
import MoneySummary from './pages/MoneySummary';
import LoginPage from './pages/LoginPage';
import DailyHours from './pages/DailyHours';
import MealPlan from './pages/MealPlan';
import Timeline from './pages/Timeline';

import { firestore, auth } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const App = () => {
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'test'));
        const newData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setData(newData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      fetchData();
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <Router>
      {user ? (
        <LayoutContainer user={user} onSignOut={handleSignOut}>
          <Routes>
            <Route path="/" element={<Navigate to="transactions" />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="meal-plan" element={<MealPlan />} />
            <Route path="timeline" element={<Timeline />} />
            <Route path="shopping-list" element={<ShoppingList />} />
            <Route path="notes" element={<Notes />} />
            <Route path="loans-debt" element={<LoanDebt />} />
            <Route path="budget-summary" element={<BudgetSummary data={data} />} />
            <Route path="bills-list" element={<BillsList />} />
            <Route path="money-summary" element={<MoneySummary />} />
            <Route path="daily-hours" element={<DailyHours />} />
          </Routes>
        </LayoutContainer>
      ) : (
        <LoginPage />
      )}
    </Router>
  );
};

export default App;
